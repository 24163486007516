import { render, staticRenderFns } from "./DialogAddDetail.vue?vue&type=template&id=8dc9d236&scoped=true"
import script from "./DialogAddDetail.vue?vue&type=script&lang=js"
export * from "./DialogAddDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dc9d236",
  null
  
)

export default component.exports