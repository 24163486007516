<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(handleUpdateDetail)">
        <div style="width: 100%; height: 1px; background: gray; margin-top: -20px; margin-bottom: 20px"></div>
        <div style="display: grid; grid-template-columns: 4fr 4fr 4fr; gap: 40px">
          <ValidationProvider name="Loại hạng mục" rules="required" v-slot="{ errors,classes }" vid="date">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Hạng mục <span style="color: red">*</span></span>
              <el-select v-model="honorDetail.category_honor.id" placeholder="Chọn hạng mục vinh danh" size="small">
                <el-option
                    v-for="item in typeOptionCategory"
                    :key="item.id"
                    :label="item.category"
                    :value="item.id">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="date" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div style="display: grid; grid-template-columns: 4fr 4fr 4fr; gap: 40px; margin-top: 15px">
          <ValidationProvider name="Tên vinh danh" rules="required|max:20" v-slot="{ errors,classes }" vid="name">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Tên vinh danh <span style="color: red">*</span></span>
              <el-input size="small" v-model="honorDetail.name" placeholder="Nhập tên nhân sự, đơn vị"/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider name="Mô tả vinh danh" rules="required" v-slot="{ errors,classes }" vid="name">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Mô tả <span style="color: red">*</span></span>
              <el-input size="small" v-model="honorDetail.description" placeholder="Nhập mô tả"/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider v-if="visibleDepartment" name="Đơn vị vinh danh" rules="required" v-slot="{ errors }" vid="department">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Đơn vị <span style="color: red">*</span></span>
              <el-input size="small" v-model="honorDetail.apartment" placeholder="Nhập đơn vị"/>
              <div class="fv-plugins-message-container">
                <div data-field="Đơn vị vinh danh" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div style="display: flex; flex-direction: column; gap: 10px; margin-top: 15px">
          <span class="page-filter__title">Tiêu đề bài viết</span>
          <el-input
              type="textarea" size="small"
              :autosize="{ minRows: 1, maxRows: 2}"
              v-model="honorDetail.title"
              placeholder="Nhập tiêu đề bài viết"
          />
        </div>
        <div style="display: flex; flex-direction: column; gap: 10px; margin-top: 25px">
          <span class="page-filter__title">Nội dung bài viết</span>
          <el-input size="small" type="textarea" v-model="honorDetail.content" placeholder="Nhập nội dung"/>
        </div>
        <div style="display: flex; flex-direction: column; gap: 10px; margin-top: 25px">
          <span class="page-filter__title">Ảnh <span style="color: red">*</span></span>
          <UploadVideo
              :fileListProp="honorDetail.attach_video_image_files"
              :mediaIds="honorDetail.media_ids"
              @emitUpdateFile="emitUpdateFile"
              :is_limited= true
              :is_image= true
          ></UploadVideo>
        </div>
        <div style="display: flex; justify-content: end; margin-top: 30px; gap: 10px">
          <el-button style="background: #CACACA" @click="closeDialog()">Hủy bỏ</el-button>
          <button
              :disabled="loading"
              style="background: #5D04B0; padding: 0 10px; border-radius: 5px; color: white; position:relative;"
              type="submit">
            <i v-if="loading" class="el-icon-loading text-white float-righ"></i>
            Xác nhận
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {HonorCategoryModel} from "@/view/pages/honors/Model/honorModel";
import {GET_LIST_HONOR_CATEGORY, UPDATE_NEWSLETTER} from "@/core/services/store/honors/honor.module";
import UploadVideo from "@/view/pages/classes/components/upload-Data-Class.vue";

export default {
  components: {UploadVideo},
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    honorDetail: {
      type: Object,
      // required: true,
      default: () => {
        return false;
      }
    }
  },
  data() {
    return {
      fileList: [],
      loading: false,
      typeOptionCategory: [],
    }
  },
  computed: {
    visibleDepartment(){
      const categorySelected = this.typeOptionCategory.find((item) => item.id === this.honorDetail?.category_honor?.id)
      return categorySelected?.type === 0;
    }
  },
  mounted() {
    this.getListCategory();
  },

  methods: {

    async getListCategory() {
      this.typeOptionCategory = await this.$store.dispatch(GET_LIST_HONOR_CATEGORY, {
        program_id : this.$route.params.id
      }).then(data => {
        if (data.data.length > 0) {
          return data.data.map(item => {
            return new HonorCategoryModel(
                item?.id,
                item?.category_name,
                item?.honor_category_type,
                item?.category_desc
            )
          })
        }
        return []
      })
    },
    emitUpdateFile(e) {
      this.fileList = e;
    },
    getPayload() {
      this.honorDetail.category = this.honorDetail.category_honor.id;
      let formData = new FormData();
      for (let key in this.honorDetail) {
        if(['attach_video_image_files', 'category_honor'].includes(key)) {
          continue;
        }
        formData.append(key, this.honorDetail[key]);
      }
      for (let i = 0; i < this.fileList.length; i++) {
        formData.append('fileList', this.fileList[i].raw);
      }
      if(this.fileList.length >= 2) {
        var textError = '';
        let count = this.fileList.length;
        if(!count) {
          textError = 'Vui lòng nhập ảnh để thêm vinh danh!'
        }else {
          textError = 'Số lượng ảnh không quá 1 ảnh!'
        }
        this.$notify({
          title: "Thất bại",
          message: textError,
          type: "error",
        });
        return false;
      }
      return formData;
    },

    handleUpdateDetail() {
      this.loading = true;
      let payload = this.getPayload();
      this.$store.dispatch(UPDATE_NEWSLETTER, payload).then((res) => {
        this.$message.success('Sửa vinh danh thành công')
        this.$emit('getListDetail');
        this.closeDialog();
      }).catch(() => {

      });
    },
    closeDialog() {
      this.loading = false;
      this.$emit('closeDialog')
    },
  }
}

</script>
<style scoped>
.honor-image__item:hover .btn-remove__image {
  display: block;
}
</style>