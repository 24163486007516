<template>
  <div class="bg-white" style="overflow: hidden">
    <div class="page-header">
      <h3 class="page-title" style="font-size: 18px;">
        Chi tiết chương trình
        <span style="text-transform: lowercase">
          {{this.$route.query.honorName}}
        </span>
      </h3>
    </div>
    <div style="width: 100%; height: 1px; background: #D6D5D5"></div>
    <div class="layout-detail">
      <div>
        <ListCategory :parent-id="this.$route.params.id"/>
      </div>
      <div>
        <ListHonorDetail :parent-id="this.$route.params.id"/>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ListCategory from "@/view/pages/honors/component/ListCategory.vue";
import ListHonorDetail from "@/view/pages/honors/component/ListHonorDetail.vue";

export default {
  components: {ListHonorDetail, ListCategory},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách vinh danh", route: "honorsList" },
      {title: "Chi tiết vinh danh"}
    ]);
    document.body.classList.remove('no-scroll');
  },
}
</script>

<style lang="css" scoped>
.page-header {
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
  align-items: center;
}
.layout-detail {
  padding: 18px 24px;
  display: grid;
  grid-template-columns: 4fr 8fr;
  gap: 25px
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .layout-detail {
    display: grid;
    grid-template-columns: 1fr;
  }
}
</style>