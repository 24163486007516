<template>
  <div id="ListHonorDetail">
    <div class="page-header" style="display: flex; justify-content: space-between; padding: 18px 0; align-items: center;">
      <h3 class="page-title" style="font-size: 16px; font-weight: 400">Danh sách vinh danh</h3>
      <button style="background: #1BC5BD; border-radius: 3px; padding: 5px 6px; color: white" @click="addHonor = true">
        Thêm vinh danh
      </button>
    </div>
    <div class="table-responsive" style="overflow: auto; height: 58vh">
      <table class="table" style="text-align: center;">
        <thead style="position: sticky; top: 0">
        <tr style="border: 1px solid #EBEDF3">
          <th scope="col" style="background: #F8F8F8; width: 40px">STT</th>
          <th scope="col" class="min-w-150px" style="background: #F8F8F8; text-align: start">Tên nhân sự</th>
          <th scope="col" class="min-w-150px" style="background: #F8F8F8; text-align: start">Tên hạng mục</th>
          <th scope="col" class="w-100px" style="background: #F8F8F8; text-align: center">Like</th>
          <th scope="col" class="w-100px" style="background: #F8F8F8; text-align: center">Bình luận</th>
          <th scope="col" class="w-100px" style="background: #F8F8F8; text-align: center">Chia sẻ</th>
          <th scope="col" class="w-160px" style="background: #F8F8F8">Hành động</th>
        </tr>
        </thead>
        <tbody v-if="loading">
        <tr class="col-12">
          <td class="text-center"><i class="el-icon-loading" style="position: absolute; left: 50%; margin: 15px 0"></i>
          </td>
        </tr>
        </tbody>
        <tbody v-else-if="honorDetailList?.length > 0">
        <tr v-for="(item, index) of honorDetailList" :key="item?.id" style="border: 1px solid #EBEDF3; border-top: 0">
          <td>{{ index + 1 }}</td>
          <td style="text-align: start">{{ item?.name }}</td>
          <td style="text-align: start">{{ item?.category }}</td>
          <td style="text-align: center">{{ item?.like }}</td>
          <td style="text-align: center">{{ item?.comment ?? 0}}</td>
          <td style="text-align: center">{{ item?.share }}</td>
          <td>
            <div style="display: flex; justify-content: center; align-items: center; gap: 10px">
              <button class="btn-action" style="border-color: #0066FF" @click="openEdit(item)">
                <i class="el-icon-edit" style="color: #0066FF"></i>
              </button>
              <button class="btn-action" style="border-color: #FF0000" @click="openDelete(item)">
                <i class="el-icon-delete" style="color: #FF0000"></i>
              </button>
            </div>
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr style="word-break: break-word; white-space: nowrap; position: relative">
          <label style="position: absolute; left: 50%; translate: -50%; top: 10px">Chưa có vinh danh</label>
        </tr>
        </tbody>
      </table>
    </div>
    <div style="display: flex; justify-content: end;" v-if="honorDetailList?.length > 0">
      <span>Tổng số bản ghi: <span style="color: #0066FF">{{honorDetailList?.length}}</span></span>
    </div>
    <b-modal v-model="addHonor" id="addHonorDialog"
             title="Thêm mới vinh danh"
             size="lg"
             ok-title="Cập nhật"
             centered
             hide-footer>
      <DialogAddDetail
          :add-honor-detail="addHonor"
          :id-selected="honorDetail.id"
          @closeDialog="addHonor = false"
          @getListDetail="getListHonorDetail"
      />
    </b-modal>
    <el-dialog title="Sửa vinh danh"
               :show-close="false" :visible.sync="isEdit" width="40%"
               :destroy-on-close="true" :center="true"
    >
      <DialogEditDetail
          v-if="isEdit"
          :is-edit="isEdit"
          :honor-detail="honorDetail"
          @closeDialog="isEdit = false"
          @getListDetail="getListHonorDetail"
      />
    </el-dialog>
    <el-dialog title="Xoá vinh danh"
               :show-close="false" :visible.sync="isDelete" width="30%"
               :destroy-on-close="true" :center="true"
    >
      <div style="width: 100%; height: 1px; background: gray; margin-top: -20px; margin-bottom: 20px"></div>
      <span style="word-break: break-word">Bạn có chắc chắn muốn xóa vinh danh “{{honorDetail?.name}}” không?</span>
      <div style="display: flex; justify-content: end; margin-top: 30px">
        <el-button style="background: #CACACA" @click="isDelete = false">Hủy bỏ</el-button>
        <button
            class="ml-3"
            :disabled="loading"
            style="background: #5D04B0; padding: 0 10px; border-radius: 5px; color: white; position:relative;"
            @click="handleDeleteDetail"
        >
          <i v-if="loading" class="el-icon-loading text-white float-righ"></i>
          Xác nhận
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DialogAddDetail from "@/view/pages/honors/component/DialogAddDetail.vue";
import DialogEditDetail from "@/view/pages/honors/component/DialogEditDetail.vue";
import {DELETE_HONOR_DETAIL, GET_LIST_HONOR_DETAIL} from "@/core/services/store/honors/honor.module";
import detail from "@/view/pages/schedule-course/detail.vue";
import {HonorDetailModel} from "@/view/pages/honors/Model/honorModel";

export default {
  components: {DialogEditDetail, DialogAddDetail},
  mounted() {
    this.getListHonorDetail();
  },
  data() {
    return {
      loading: false,
      honorDetailList: [],
      addHonor: false,
      isEdit: false,
      isDelete: false,
      honorDetail: {}
    }
  },
  props: {
    parentId: {
      type: Number,
      required: true,
      default: null,
    }
  },
  methods: {
    async getListHonorDetail() {
      this.honorDetailList = await this.$store.dispatch(GET_LIST_HONOR_DETAIL, {program_id: this.parentId}).then((res) => {
        if (res.data.length > 0) {
          return res.data.map(item => {
            return new HonorDetailModel(
                item?.id,
                item?.name_honor,
                item?.category_honor?.category_name,
                item?.newsletter_like,
                item?.newsletter_share,
                item?.comment,
                item?.content,
                item?.newsletter_desc,
                item?.department,
                item?.newsletter_title,
                item?.attach_video_image_files,
                item?.media_ids,
                item?.category_honor,
            )
          })
        }
      })
    },
    openEdit(detail) {
      this.isEdit = true;
      this.honorDetail = detail;
    },
    openDelete(detail) {
      this.isDelete = true
      this.honorDetail = detail
    },
    handleDeleteDetail() {
      this.loading = true;
      this.$store.dispatch(DELETE_HONOR_DETAIL, this.honorDetail.id).then(() => {
        this.$message.success('Xóa vinh danh thành công')
        this.getListHonorDetail();
        this.isDelete = false
        this.loading = false;
      })
    }
  }
}
</script>

<style lang="css">

.btn-action {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid;
}
</style>